import React, { useEffect } from 'react'
import style from './NiceReserv.module.scss'
import { Header } from '../Header/Header'
import { Footer } from '../Footer/Footer'
import Card from './Card/Card'
import EndCard from '../EndCard/EndCard'


const NiceReserv = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.height = 'auto';
        document.body.style.overflow = 'auto';
    }, [])

    interface apart {
        id: string;
    }

    const aparts = [{ id: '1' }, { id: '2' }, { id: '2' }] as apart[];
    const height = window.innerHeight;
    return (
        <div className={style.niceReserv}>
            <Header color='black' page='rsrv' />
            <div className={style.content}>
                <div className={style.top} style={{ height: `calc(${height}px - 115px)` }}>
                    <div></div>
                    <div className={style.text}>
                        <span className={`${style.name} wow fadeInRight`}>Бронирование</span>
                        <span className={`${style.descr} wow fadeInUp`}>Ваше уютное убежище вдали от дома ждет вас здесь и сейчас</span>
                    </div>
                    <Card id={aparts[0].id} />
                </div>
                <div className={style.block}>
                    <Card id={aparts[1].id} />
                    <EndCard />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NiceReserv