import React from 'react'
import style from './Scenario.module.scss'
import { Header } from '../../Header/Header'

interface IScenarioProps {
    img: String,
    nameRussia: String,
    nameEnglish: String,
    textRussia: String,
    textEnglish: String
}

const Scenario: React.FC<IScenarioProps> = ({ img, nameRussia, nameEnglish, textRussia, textEnglish }) => {
    return (
        <div className={`${style.scenario} wow fadeInUp`}>
            <div className={style.header}>
                <img src={`images/${img}`} className={`${style.imgInsta}`} />
                <div className={`${style.name}`}>
                    <span className={style.nameRussia}>{nameRussia}</span>
                    {/* <span className={style.nameEnglish}>{nameEnglish}</span> */}
                </div>
            </div>
            <div className={`${style.text}`}>
                <div className={style.textRussia}>{textRussia}</div>
                {/* <div className={style.textEnglish}>
                    {textEnglish}
                </div> */}
            </div>
        </div>
    )
}

export default Scenario