import React from 'react'
import styles from './Status.module.scss'

interface IStatusProps {
    src: string,
    text: string
}

const Status: React.FC<IStatusProps> = ({ src, text }) => {
    return (
        <div className={styles.success}>
            <img className='wow bounceIn' src={src} />
            <span className='wow slideInUp'>{text}</span>
        </div>
    )
}

export default Status