import { Route, Routes, BrowserRouter } from 'react-router-dom'
import Home from '../components/Home/Home'
import { ROUTES } from '../utils/route'
import { Reserv } from '../components/Reserv/Reserv'
import Scenarios from '../components/Scenarios/Scenarios'
import { MainGallery } from '../components/Galery/MainGallery'
import NiceReserv from '../components/NiceReserv/NiceReserv'
import Contact from '../components/Contact/Contact'
import PageApart from '../components/PageApart/PageApart'
const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route index element={<Home />} />
      <Route path={ROUTES.GALLERY} element={<MainGallery isHome={false}/>} />
      <Route path={ROUTES.RESERVATION} element={<NiceReserv />} />
      <Route path={ROUTES.SCENARIOS} element={<Scenarios />} />
      <Route path={ROUTES.CONTACTS} element={<Contact />} />
      <Route path={ROUTES.APART} element={<PageApart />} />
    </Routes>
  </BrowserRouter>
)

export default AppRoutes