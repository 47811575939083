import React, { useEffect, useRef, useState } from 'react'
import styles from './MainGallery.module.scss'
import { NavLink } from 'react-router-dom'
import LightGallery from 'lightgallery/react'

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';

interface IMainGalleryProps {
    isHome: Boolean;
}

export const MainGallery: React.FC<IMainGalleryProps> = ({ isHome }) => {


    const height = window.innerHeight;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {!isHome &&
                <Header color='black' page='gallery' arrowtext='Ознакомиться' arrowColor='black' />
            }
            <div className={styles.galleryMain}>
                {/* {!isHome && <NavLink className={`${styles.back} image wow fadeInLeft`} to={'/'}></NavLink>} */}
                <div className={styles.block} style={{ height: ` calc(${height}px - 75px)` }}>
                    <span className={`${styles.name} wow fadeInLeft`}>Галерея</span>
                    <span className={`${styles.text} wow fadeInUp`}>Эти фотографии приглашают вас в мир изысканных
                        апартаментов, где комфорт встречается с элегантностью,
                        и каждая деталь выражает исключительный стиль и
                        гармонию.</span>
                </div>
                <div className={styles.photos}>

                    <LightGallery
                        speed={500}
                        plugins={[lgThumbnail]}
                    >
                        <a href="images/photo1.jpg">
                            <img className={`${styles.name}`} data-wow-delay="0.3s" alt="нормально" src="images/photo1.jpg" />
                            <span className={`${styles.descr}`}>Москва Сити</span>
                        </a>
                        <a href="images/photo2.jpg">
                            <img className={`${styles.name}`} data-wow-delay="0.3s" alt="нормально" src="images/photo2.jpg" />
                            <span className={`${styles.descr}`}>Интерьер</span>
                        </a>
                        <a href="images/photo3.jpg">
                            <img className={`${styles.name}`} data-wow-delay="0.3s" alt="общайся" src="images/photo3.jpg" />
                            <span className={`${styles.descr}`}>Хз можно</span>
                        </a>
                        <a href="images/photo4.jpg">
                            <img className={`${styles.name}`} data-wow-delay="0.3s" alt="нормально" src="images/photo4.jpg" />
                            <span className={`${styles.descr}`}>Что-нибудь</span>
                        </a>
                        {!isHome && <>
                            <a href="images/photo2.jpg">
                                <img className={`${styles.name}`} data-wow-delay="0.3s" alt="общайся" src="images/photo2.jpg" />
                                <span className={`${styles.descr}`}>Жесткое</span>
                            </a>
                            <a href="images/photo1.jpg">
                                <img className={`${styles.name}`} data-wow-delay="0.3s" alt="нормально" src="images/photo1.jpg" />
                                <span className={`${styles.descr}`}>Придумать</span>
                            </a>
                            <a href="images/photo3.jpg">
                                <img className={`${styles.name}`} data-wow-delay="0.3s" alt="общайся" src="images/photo3.jpg" />
                                <span className={`${styles.descr}`}>Что здесь</span>
                            </a>
                            <a href="images/photo4.jpg">
                                <img className={`${styles.name}`} data-wow-delay="0.3s" alt="нормально" src="images/photo4.jpg" />
                                <span className={`${styles.descr}`}>Написать</span>
                            </a>
                            <a href="images/photo2.jpg">
                                <img className={`${styles.name}`} data-wow-delay="0.3s" alt="общайся" src="images/photo2.jpg" />
                                <span className={`${styles.descr}`}>Жесткое</span>
                            </a>
                            <a href="images/photo1.jpg">
                                <img className={`${styles.name}`} data-wow-delay="0.3s" alt="нормально" src="images/photo1.jpg" />
                                <span className={`${styles.descr}`}>Придумать</span>
                            </a>
                            <a href="images/photo3.jpg">
                                <img className={`${styles.name}`} data-wow-delay="0.3s" alt="общайся" src="images/photo3.jpg" />
                                <span className={`${styles.descr}`}>Что здесь</span>
                            </a>
                            <a href="images/photo4.jpg">
                                <img className={`${styles.name}`} data-wow-delay="0.3s" alt="нормально" src="images/photo4.jpg" />
                                <span className={`${styles.descr}`}>Написать</span>
                            </a>
                        </>
                        }

                    </LightGallery>
                    {isHome && <div className={styles.goGallery}>
                        <NavLink className={`${styles.link} wow fadeInUp`} to="/galery">Перейти в Галерею</NavLink>
                    </div>}
                </div>
                {!isHome && <Footer page={'gallery'} />}
            </div>
        </>
    )
}
