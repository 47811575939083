import React, { useState, useEffect, useRef } from 'react'
import styles from './ResMenu.module.scss'
import { NavLink } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { sendMessage } from './Form';
import LoadingButton from './Status/LoadingButton';
import Status from './Status/Status';

interface IResMenuProps {
    direction: string;
    handleClose: () => void;
}
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export const ResMenu: React.FC<IResMenuProps> = ({ direction, handleClose }) => {
    const height = window.innerHeight;
    const [isOpenDateIn, setIsOpenDateIn] = useState(false);
    const [isOpenDateOut, setIsOpenDateOut] = useState(false);
    const [dateIn, setDateIn] = useState<Value>(new Date());
    const [dateOut, setDateOut] = useState<Value>(new Date());

    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState(false)
    const [succes, setSuccess] = useState(false);

    const [validError, setValidError] = useState(false);

    const statusText = {
        error: 'Произошла ошибка. Проверьте подключение к интернету',
        success: 'Бронь успешно оставлена! В ближайшее время с вами свяжется менеджер',
        valid: 'Проверьте введенные данные'
    }
    useEffect(() => {
        clearDate();
    }, [])

    const clearDate = () => {
        setDateIn(new Date)
        if (!Array.isArray(dateIn) && dateIn)
            setDateOut(new Date(dateIn.getFullYear(), dateIn.getMonth(), dateIn.getDate() + 1))
    }

    const handleOpenDateIn = () => {
        setIsOpenDateIn(!isOpenDateIn);
    }

    const handleOpenDateOut = () => {
        setIsOpenDateOut(!isOpenDateOut);
    }

    const niceDay = (date: Value) => {
        if (!Array.isArray(date) && date) {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}.${year}`
        }
    }
    const closeAll = () => {
        setIsOpenDateIn(false);
        setIsOpenDateOut(false);
    }


    const searchId = useRef<HTMLDivElement>(null);
    document.addEventListener('mousedown', function (event) {
        if (!searchId.current?.contains(event.target as Node) && (dateIn || dateOut)) {
            setIsOpenDateIn(false);
            setIsOpenDateOut(false);
        };
    });

    const [name, setName] = useState('');

    const [phone, setPhone] = useState('');

    const validPhone = () => {
        const re = /^[\d\+][\d\(\)\ -]{4,14}\d$/;
        if (re.test(phone) && name.trim() !== "") goToSend()
        else setValidError(true)
    }

    // Создаём функции для изменения состояния имени и валидации имени.
    const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        if (validError) {
            setValidError(false);
        }
        if (succes) {
            setSuccess(false);
        }
    };

    // Функци для изменения телефона и валидации телефона.
    const changeHandlerPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(e.target.value);
        if (validError) {
            setValidError(false);
        }
        if (succes) {
            setSuccess(false);
        }
    };

    const clear = () => {
        clearDate();
        setPhone('');
        setName('');
    }


    const goToSend = () => {
        setIsSending(true)
        setError(false);
        setSuccess(false);
        sendMessage(name, phone, niceDay(dateIn), niceDay(dateOut))
            .then(() => {
                console.log('Бронь успешно отправлена');
                setSuccess(true);
                setIsSending(false)
                clear();
            })
            .catch(() => {
                console.log('Ошибка');
                setError(true);
                setIsSending(false);
            })
    }




    return (
        <div className={`${styles.resMenu} `}
            style={{ height: `${height}px` }}>
            <button className={styles.close} onClick={handleClose}>
                <img src='/images/close.svg'></img>
            </button>
            <div className={styles.block}>
                {/* <div className={`${styles.scenarios} wow fadeInRight`}>
                    <span className={styles.name}>Выберите сценарий</span>
                    <NavLink className={styles.descr} to='/scenarios'>Узнать подробнее</NavLink>
                    <button>
                        <span>Романтик</span>
                        <span>5000₽</span>
                    </button>
                    <button>
                        <span>Гурман</span>
                        <span>3000₽</span>
                    </button>
                    <button>
                        <span>Игроман</span>
                        <span>2500₽</span>
                    </button>
                    <button>
                        <span>Инстаблядь</span>
                        <span>2000₽</span>
                    </button>
                </div> */}
                <div className={`${styles.resBlock} wow fadeInUp`}>
                    <span className={styles.name}>Бронирование</span>

                    <input onChange={changeName} value={name} placeholder='Введите имя'></input>
                    <input onChange={changeHandlerPhone} value={phone} placeholder='Введите номер телефона'></input>

                    <button className={styles.dateIn} onClick={handleOpenDateIn}>
                        <span className={styles.text}>Дата заезда:</span>
                        <span className={styles.date}>{niceDay(dateIn)}</span>
                    </button>
                    <button className={styles.dateIn} onClick={handleOpenDateOut}>
                        <span className={styles.text}>Дата выезда:</span>
                        <span className={styles.date}>{niceDay(dateOut)}</span>
                    </button>
                    <button onClick={validPhone} className={styles.bron}>ЗАБРОНИРОВАТЬ</button>
                    {(isSending || error || succes || validError) && <div className={`${styles.status}`}>
                        {isSending ? <LoadingButton /> :
                            error ? <Status src={'/images/error.svg'} text={statusText.error} /> :
                                validError ? <Status src='/images/valid.svg' text={statusText.valid} /> :
                                    succes ? <Status src='/images/succes.svg' text={statusText.success} /> :
                                        ''}
                    </div>
                    }
                    <div className={styles.calendar} ref={searchId}>
                        {(isOpenDateIn || isOpenDateOut) &&
                            <>
                                <div className={`${styles.content} wow fadeInUpBig`} data-wow-duration="0.3s">
                                    <div className={styles.top}>
                                        <span>{isOpenDateIn ? 'Дата заезда' : 'Дата выезда'}</span>
                                    </div>
                                    {isOpenDateIn && !Array.isArray(dateOut) && dateOut && <Calendar defaultValue={dateOut} onChange={setDateIn} value={dateIn} />}
                                    {isOpenDateOut && !Array.isArray(dateIn) && dateIn && <Calendar defaultValue={dateIn} onChange={setDateOut} value={dateOut} />}
                                    <div className={styles.chose} onClick={closeAll}>Выбрать</div>
                                </div>
                                <div className={`${styles.background} wow fadeIn`} data-wow-duration="0.3s" />
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
