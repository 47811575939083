import React, { useEffect } from 'react'
import style from './Scenarios.module.scss'
import { NavLink } from 'react-router-dom'
import Scenario from './Scenario/Scenario'
import { Header } from '../Header/Header'
import { Footer } from '../Footer/Footer'

const Scenarios = () => {
    const height = window.innerHeight;

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.height = 'auto';
        document.body.style.overflow = 'auto';
    }, [])
    return (
        <div className={style.scenarios}>
            <Header color='white' page='scn' arrowtext='Ознакомиться'/>
            {/* <div className={`${style.logo} wow fadeIn`}>
                <img src='images/logogold.svg' />
                <div className={style.textLogo}>
                    <span>COZY</span>
                    <span>APARTS</span>
                </div>
            </div> */}
            <div className={style.info} style={{ height: `calc(${height}px - 145px)` }}>
                <div className={`${style.textInfo} wow fadeIn`} data-wow-delay="0.2s">
                    <div className={style.nameScen}>
                        <span className={style.nameRussia}>СЦЕНАРИИ</span>
                        {/* <span className={style.nameEnglish}>scenarios</span> */}
                    </div>
                    <div>
                        <p className={style.textRussia}>
                            Вы можете выбрать один из сценариев, по которому вы хотите провести день, чтобы сделать его более
                            запоминающимся и неповторимым. Чтобы узнать больше о каждом из сценариев,
                            обратитесь к вашему менеджеру.
                        </p>
                        {/* <p className={style.textEnglish}>
                            You can choose one of the scenarios  to make your day more  memorable and unique. For more Detail about each,
                            contact your manager.
                        </p> */}
                    </div>
                </div>
            </div>
            <div className={style.lineBlock}>
                <Scenario img={'rom.svg'} nameRussia={'Романтик'} nameEnglish={'ROMANTIQUE'}
                    textRussia={'"Под мягким светом свечей, двое влюбленных наслаждаются уютным ужином на двоих, сопровождаемым тихой музыкой  и блеском звезд в окне."'}
                    textEnglish={'"Beneath the soft glow of candles, two lovers enjoy a cozy dinner for two, accompanied by gentle music and the twinkle of stars outside the window"'}
                />
                <Scenario img={'game.svg'} nameRussia={'Игровой'} nameEnglish={'GAME'}
                    textRussia={'"Все смеются и наслаждаются захватывающими моментами,  играя в разнообразные игры, окруженные уютной атмосферой."'}
                    textEnglish={'"Everyone is laughing and enjoying thrilling moments, playing a variety of games, surrounded by a cozy atmosphere."'}
                />
            </div>
            <div className={style.lineBlock}>
                <Scenario img={'gur.svg'} nameRussia={'гурман'} nameEnglish={'gourmet'}
                    textRussia={'"На элегантно сервированном столе расположились изысканные блюда. кажется, это то, чем я хотел закончить этот вечер"'}
                    textEnglish={'"On the elegantly set table lay exquisite dishes. It seems this is what I wanted to end this evening with."'}
                />
                <Scenario img={'insta.svg'} nameRussia={'ИНСТАБЛЯДЬ'} nameEnglish={'INSTABLYAD'}
                    textRussia={'"баби бадум баКа кида ваце амениариу вк сицдым параллнель вада фывк цуке плаопмтом аманей"'}
                    textEnglish={'"Babi Badum baKa Kida Vace Ameniariu VK sitsdym parallel vada fyvk tsuke plaopmtom amaney"'}
                />

            </div>
            <div className={`${style.footer}`}>
                <div className={style.line}>
                    <img src='images/square.svg'></img>
                    <img src='images/lineDown.svg'></img>
                    <img src='images/square.svg'></img>
                </div>
                <div className={style.text}>
                    <p className={style.textRussia}>
                        Для уточнения стоимости и оплаты свяжитесь с вашим
                        менеджером или по номеру +7 (910) 181-15-08
                    </p>
                    {/* <p className={style.textEnglish}>
                        For pricing details and payment,  please contact your manager
                        or call +7 (910) 181-15-08
                    </p> */}
                </div>
            </div>
            {/* <NavLink to={'/'} className={`${style.logo} ${style.mobileLogo}`}>
                <img src='images/logogold.svg' />
                <div className={style.textLogo}>
                    <span>COZY</span>
                    <span>APARTS</span>
                </div>
            </NavLink> */}
            <Footer page={'scn'} />
        </div>
    )
}

export default Scenarios