import React, { useEffect, useState } from 'react'
import styles from './Header.module.scss'
import { NavLink } from 'react-router-dom';
import { $LG } from 'lightgallery/lgQuery';

interface IHeaderProps {
    color: string;
    page?: string;
    arrowColor?: string;
    arrowtext?: string;
}

export const Header: React.FC<IHeaderProps> = ({ color, page, arrowColor, arrowtext }) => {
    const [isFixed, setIsFixed] = useState(false);
    const [preVIsFixed, setPrevIsFixed] = useState(false);
    const [isOpenNav, setIsOpenNav] = useState(false);
    const [posTop, setPosTop] = useState(0);
    const [up, setUp] = useState(false);
    const [scrollPrev, setScrollPrev] = useState(0);
    const [isArrow, setIsArrow] = useState(true);

    useEffect(() => {
        document.body.style.height = 'auto';
        document.body.style.overflow = 'auto';
    }, [])

    useEffect(() => {
        if (preVIsFixed !== isFixed && !isOpenNav) {
            if (preVIsFixed === false && !up) {
                setUp(true);
                setTimeout(() => {
                    setUp(false);
                }, 150)
            }
            setIsFixed(preVIsFixed)
        }
    }, [preVIsFixed])
    const height = window.innerHeight;
    window.addEventListener('scroll', function () {
        const top = document.documentElement.scrollTop;
        if (top > height !== isFixed && !isOpenNav)
            setPrevIsFixed(top > height);
        setIsArrow(top < 1)
    });
    const handleOpen = () => {
        setScrollPrev(document.documentElement.scrollTop);
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100vh';
        setIsOpenNav(true);
    }
    const handleClose = () => {
        document.body.style.height = 'auto';
        document.body.style.overflow = 'auto';
        console.log(scrollPrev);
        window.scrollTo(0, scrollPrev);
        setIsOpenNav(false);
    }


    return (
        <>{(isFixed || up) && <div className={styles.fakeHeader}>
        </div>}
            <div className={`${styles.top} wow fadeInLeft ${isFixed ? styles.fixed : ''} ${up ? styles.setUp : ''}`} style={{
                color: color,
                borderBottom: `1px solid ${isFixed ? 'white' : color}`
            }} data-wow-duration="0.6s">
                <a href="tel:+79101811508" className={`${styles.numberHide} ${styles.number}`}>7 910 181 15 08</a>

                <NavLink to='/' className={`${styles.logo} ${isFixed ? styles.fixed : ''
                    } wow fadeIn`} data-wow-delay="0.6s">
                    <img src={`/images/logo${(color === 'black' || isFixed) ? 'black' : 'white'}.svg`} />
                </NavLink>

                <div className={`${styles.nav} wow fadeIn ${color === 'black' || isFixed ? `${styles.black}` : ''} wow fadeIn`} data-wow-delay="0.9s">
                    <ul>
                        {
                            page === 'scn' ?
                                <NavLink className={styles.link} to='/'>на главную</NavLink>
                                :
                                <NavLink className={styles.link} to='/scenarios'>сценарии</NavLink>
                        }
                        {
                            page === 'gallery' ?
                                <NavLink className={styles.link} to='/'>на главную</NavLink>
                                :
                                <NavLink className={styles.link} to='/galery'>галерея</NavLink>
                        }
                        <NavLink className={styles.link} to='/contacts'>контакты</NavLink>
                        <NavLink className={styles.link} to='/'>О нас</NavLink>
                    </ul>
                </div>

                <>
                    {!isFixed && !up && page !== 'cnt' &&
                        <a href="tel:+79101811508" className={`${styles.number} wow fadeIn ${(page === 'rsrv' || page === 'apart') ? styles.topNumber : ''} ${isFixed ? `${styles.fixed}` : ''}`} data-wow-delay="0.5s" style={{
                            color: isFixed ? 'black' : color,
                            backgroundImage: `${(isFixed || color === 'black') ? 'url(images/phone_black.svg)' : 'url(images/phone.svg)'}`
                        }}>7 910 181 15 08</a>
                    }
                    {page !== 'rsrv' && page !== 'apart' &&
                        <NavLink className={`${styles.bron} wow fadeIn`} to='/reservation' data-wow-delay="0.5s"><span>
                            бронирование
                        </span>
                        </NavLink>
                    }
                </>


                <div className={styles.navMobile}>
                    <button className={`wow fadeIn ${color === 'black' || isFixed ? `${styles.black}` : ''} ${styles.button}`} data-wow-delay="0.9s" onClick={handleOpen} />
                    {isOpenNav && <div className={`${styles.navMenuMobile}`} >
                        <button className={styles.close} onClick={handleClose} />
                        <ul>
                            {
                                page === 'rsrv' ?
                                    <NavLink className={`${styles.link} wow fadeInRight`} to="/" data-wow-delay="0.2s">на главную</NavLink>
                                    :
                                    <NavLink className={`${styles.link} wow fadeInRight`} to="/reservation" data-wow-delay="0.0s">бронирование</NavLink>
                            }
                            {
                                page === 'scn' ?
                                    <NavLink className={`${styles.link} wow fadeInRight`} to="/" data-wow-delay="0.2s">на главную</NavLink>
                                    :
                                    <NavLink className={`${styles.link} wow fadeInRight`} to="/scenarios" data-wow-delay="0.1s">сценарии</NavLink>
                            }
                            {
                                page === 'gallery' ?
                                    <NavLink className={`${styles.link} wow fadeInRight`} to="/" data-wow-delay="0.2s">на главную</NavLink>
                                    :
                                    <NavLink className={`${styles.link} wow fadeInRight`} to="/galery" data-wow-delay="0.2s">галерея</NavLink>
                            }
                            <NavLink className={`${styles.link} wow fadeInRight`} to="/contacts" data-wow-delay="0.3s">контакты</NavLink>
                            <NavLink className={`${styles.link} wow fadeInRight`} to="/" data-wow-delay="0.5s">О нас</NavLink>
                        </ul>
                    </div>}
                </div>
            </div >
            {page !== 'rsrv' && page !== 'cnt' && page !== 'apart' && <div className={`${styles.arrow} ${arrowColor === 'black' ? styles.black : ''} ${!isArrow ? styles.hide : ''} wow fadeInUp`} style={{
                top: `${height - 60}px`
            }}>{arrowtext}</div>
            }
        </>
    )
}
