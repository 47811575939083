import React from 'react'
import styles from './Footer.module.scss'
import { NavLink } from 'react-router-dom';

interface IFooterProps {
  page?: String,
}


export const Footer: React.FC<IFooterProps> = ({ page }) => {
  const height = window.innerHeight;
  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.contact}>
            <span className={styles.name}>контактная информация</span>
            <div className={styles.info}>
              <a href="tel:+79101811508" className={styles.pos}>7 910 181 15 08</a>
              <a href="tel:+79157656876" className={styles.pos}>7 915 765 68 76</a>
              <a href="mailto:cozy_aparts@mail.ru" className={styles.pos}>cozy_aparts@mail.ru</a>
            </div>
          </div>
          <div className={styles.contact} style={{ textAlign: "right" }}>
            <span className={styles.name}>Местонахождение</span>
            <div className={styles.info}>
              <a target='_blank' href='https://yandex.ru/maps/?text=1-й Красногвардейский пр-д, 22 стр. 2, Москва' className={styles.pos}>1-й Красногвардейский пр-д, 22 стр. 2, Москва, 123100</a>
            </div>
          </div>
        </div>
        <div className={styles.nav}>
          <div className={styles.line}>
            <NavLink className={styles.link} to='/reservation'>бронирование</NavLink>
            <NavLink className={styles.link} to='/'>О нас</NavLink>
          </div>
          <div className={styles.line}>
            {page === 'gallery' ?
              <NavLink className={styles.link} to='/'>на главную</NavLink> :
              <NavLink className={styles.link} to='/galery'>галерея</NavLink>}
            {page === 'scn' ?
              <NavLink className={styles.link} to='/'>на главную</NavLink> :
              <NavLink className={styles.link} to='/scenarios'>сценарии</NavLink>}
          </div>
        </div>
        <span className={styles.cozy}>© 2023 Cozy Aparts</span>
      </div>
    </div>
  )
}
