import React, { useState } from 'react'
import styles from './ButtonRes.module.scss'
import { ResMenu } from '../ResMenu/ResMenu'

interface IbuttonResProps {
    topBron: number | undefined
}

const ButtonRes: React.FC<IbuttonResProps> = ({ topBron }) => {
    const [goBig, setGoBig] = useState(false)
    const [isFixed, setIsFixed] = useState(false);
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    const [goRes, setGoRes] = useState(false);
    const height = window.innerHeight;
    const [openRes, setOpenRes] = useState(false);
    const [scrollPrev, setScrollPrev] = useState(0);

    const [secondBlock, setSecondBlock] = useState(false);
    let top;
    window.addEventListener('scroll', function () {
        top = document.documentElement.scrollTop;


        if (top + 50 > height) {
            if (!isFixed && !openRes)
                setIsFixed(true)
        }
        else if (isFixed && !openRes) {
            setIsFixed(false)
        }

        if (top + 125 > 2 * height) {
            if (!secondBlock && !openRes)
                setSecondBlock(true)
        }
        else if (secondBlock && !openRes) {
            setSecondBlock(false)
        }

        if (top > height) {
            if (!isHeaderFixed && !openRes) {
                setIsHeaderFixed(true)
            }
        }
        else if (isHeaderFixed && !openRes) {
            setIsHeaderFixed(false)
        }

        if (topBron && top - 53.59 > topBron) {
            if (!goRes && !openRes)
                setGoRes(true)
        }
        else if (goRes && !openRes) {
            setGoRes(false)
        }
    }
    )

    const handleOpenRes = () => {
        if (!openRes) {
            setOpenRes(true)
        }
        else {
            setOpenRes(false);
        }
    }

    const handleOpen = () => {
        setOpenRes(true);
        setScrollPrev(document.documentElement.scrollTop);
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100vh';
    }
    const handleClose = () => {
        document.body.style.height = 'auto';
        document.body.style.overflow = 'auto';
        console.log(scrollPrev);
        window.scrollTo(0, scrollPrev);
        setOpenRes(false);
    }

    return (
        <>
            <div className={`${styles.buttonRes}
        ${isFixed ? styles.isFixed : ''}
        ${isHeaderFixed ? styles.isHeaderFixed : ''}
        ${goRes ? styles.res : ''}
        ${secondBlock ? styles.secondBlock : ''}
        wow fadeIn
        `
            }
            // style={!goRes ? { border: 'none' } : { top: `${topBron && topBron}px` }}
            >
                <div className={styles.price}>
                    <span className={styles.ot}>от</span>
                    <span className={styles.cash}>20000₽</span>
                </div>
                <span className={styles.button} onClick={handleOpen}>
                    забронировать
                </span>
            </div>
            {openRes && <ResMenu direction={isFixed ? 'down' : 'up'} handleClose={handleClose} />}
        </>
    )
}

export default ButtonRes