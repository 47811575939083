import React from 'react'
import styles from './Contact.module.scss'
import { Header } from '../Header/Header'
import { Footer } from '../Footer/Footer';

const Contact = () => {
    const height = window.innerHeight;
    return (
        <div className={styles.contact}>
            <Header color='black' page='cnt' />
            <div className={styles.content}
                style={{ height: `calc(${height}px - 76px)` }}>
                <span className={`${styles.name} wow fadeInUp`}>Контактная информация</span>
                <div className={`${styles.fields} wow fadeInUp`} data-wow-delay="0.3s">
                    <span className={styles.field}>+7 915 765 68 76</span>
                    <span className={styles.field}>+7 910 181 15 08</span>
                    <span className={styles.field}>cozy.aparts@yandex.ru</span>
                </div>
                <span className={`${styles.name} wow fadeInUp`} style={{marginTop:'40px'}}>Местонахождение</span>
                <div className={`${styles.fields} wow fadeInUp`} data-wow-delay="0.3s">
                    <span className={styles.field}>1-й Красногвардейский пр-д, 22 стр. 2, Москва, 123100</span>
                </div>
            </div>
        </div>
    )
}

export default Contact