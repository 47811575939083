import React from 'react'
import styles from './App.module.scss'
import AppRoutes from '../../Routes/AppRoutes'

const App:React.FC = () => {
  return (
    <div className={styles.app}>
      <AppRoutes />
    </div>
  )
}

export default App