import useState from 'react';
// Создаём состояния для наших инпутов.

export const sendMessage = async (name: string | undefined, phone: string | undefined, dateIn: string | undefined, dateOut: string | undefined) => {
    try {
        // await fetch('http://localhost:5000/telegram', {
        await fetch('http://cozyserverdomain.ru/telegram', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, phone, dateIn, dateOut }),
        })
            .then((response) => {
                response.json(); console.log('123' + response.status)
                if (response.status >= 400) {
                    throw new Error('Error');
                };
            })
    } catch (e) {
        throw new Error('Error');
    }
}

