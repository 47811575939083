import React, { useRef, useEffect, useState } from 'react'
import styles from './PageApart.module.scss'
import { useParams } from 'react-router-dom';
import ButtonRes from './ButtonRes/ButtonRes';
import LightGallery from 'lightgallery/react'
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom'
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';

const PageApart = () => {
    const { cats, id } = useParams();
    const height = window.innerHeight;
    const [apartsHeight, setApartsHeight] = useState<number | undefined>(0)
    const [imgs, setImgs] = useState([''])
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.height = 'auto';
        document.body.style.overflow = 'auto';
        setImgs(['/images/apart1/1.jpg', '/images/apart1/2.jpg', '/images/apart1/3.jpg', '/images/apart1/4.jpg', '/images/apart1/5.jpg', '/images/apart1/6.webp', '/images/apart1/main.jpg'])
    }, [])
    useEffect(() => {
        setTimeout(() => {
            //@ts-ignore;
            setApartsHeight(document?.getElementById('aparts')?.clientHeight - height - 54.6);
        }, 500)
    }, [])


    return (
        <>
            <div className={styles.pageApart} id='aparts'>
                <div className={styles.background} style={{ height: `${height + 2}px` }}>
                    <img src='/images/apart1/6.webp' />
                </div>
                <div className={`${styles.top}`} style={{ height: `${height + 1}px` }}>
                    <Header color='white' arrowtext='Подробнее' page='apart' />
                    <div className={styles.center}>
                        <p className='wow fadeIn' data-wow-delay="1.2s">Апартаменты 1</p>
                        <div className={styles.bottom}>
                            <a href='#descr' className={`${styles.button1} wow fadeInRight`}>Описание</a>
                            <a href='#gallery' className={`${styles.button2} wow fadeInLeft`}>Галерея</a>
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.top}>
                        <ButtonRes topBron={apartsHeight} />
                    </div>
                    <div id='descr' className={`${styles.block} ${styles.block2}`}
                        style={{ height: `${height}px` }}>
                        <div className={styles.in}>

                            <span className={`${styles.name} wow fadeInUp`}>Основные параметры</span>
                            <div className={`${styles.har} wow fadeInUp`} data-wow-delay="0.2s">
                                <div className={styles.left}>
                                    <span>Этаж</span>
                                    <span>Площадь</span>
                                    <span>Комнат</span>
                                    <span>Категория</span>
                                </div>
                                <div className={styles.right}>
                                    <span>30</span>
                                    <span>м²</span>
                                    <span>2</span>
                                    <span>Люкс</span>
                                </div>
                            </div>
                            <span className={`${styles.name} wow fadeInUp`} data-wow-delay="0.4s">Описание</span>
                            <span className={`${styles.text} wow fadeInUp`} data-wow-delay="0.6s">Функциональный апартамент с современной
                                отделкой, уютным интерьером, полностью
                                укомплектован бытовой техникой. </span>
                        </div>
                    </div>

                    <div id='gallery' className={`${styles.block} ${styles.galleryBlock}`}
                    // style={{ height: `${height}px` }}
                    >
                        <span className={styles.name}>Галерея</span>
                        <LightGallery
                            speed={500}
                            plugins={[lgThumbnail, lgZoom]}
                        >
                            {imgs.map((img, id) =>
                                <a id={`${id}`} href={img}>
                                    <img className={`${styles.name} wow fadeInUp`} data-wow-delay="0.3s" alt="" src={img} />
                                    {/* <span className={`${styles.descr}`}>Москва Сити</span> */}
                                </a>
                            )}
                        </LightGallery>
                    </div>
                </div>
                <div id='footer' className={styles.footer}>
                    <Footer />
                </div>
            </div >
        </>

    )
}

export default PageApart