import React, { useEffect, useRef, useState } from 'react'
import styles from './Home.module.scss'
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { NavLink } from 'react-router-dom';
import { MainGallery } from '../Galery/MainGallery';

const Home: React.FC = () => {
    const [height, setHeight] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
        setHeight(window.innerHeight);
    }, [])
    return (
        <div className={styles.home}>
            <div className={styles.background} style={{ height: `${height + 1}px` }}>
                <img src='images/background.webp' />
            </div>
            <div className={`${styles.content}`} style={{ height: `${height + 1}px` }}>
                <Header color='white' arrowtext='Подробнее' />
                <div className={styles.center}>
                    <p className='wow fadeIn' data-wow-delay="1.2s">Апартаменты
                        В Москва СИТИ</p>
                    {/* <a href='#block1'></a> */}
                </div>
            </div>
            <div className={`${styles.block} ${styles.block2}`}>
                <div className={`${styles.text}`}>
                    <span className={`${styles.title} wow fadeIn`}>
                        Москва Сити
                    </span>
                    <span className={`${styles.descr} wow fadeInUp`}>
                        Москва Сити — это уникальный и выдающийся городской комплекс,
                        который стал символом современной Москвы. Расположенный в
                        деловом и финансовом центре столицы России, Москва Сити
                        привлекает внимание своей архитектурной смелостью, роскошью
                        и инновационностью.
                    </span>
                </div>
                <div className={styles.images}>
                    <img src='images/photo1.2.jpg' className={`wow slideInRight`} />
                </div>
            </div>
            <div className={`${styles.block} ${styles.block1} ${styles.blockOne}`} style={{ flexDirection: 'column-reverse', alignItems: 'center', justifyContent: 'center' }}>
                <div className={styles.images}>
                    <img src='images/photo3.jpg' className={`${styles.img1} ${styles.blackImage} wow fadeIn`} style={{ height: '300px' }} />
                    <img src='images/photo2.jpg' className={`${styles.img2} wow fadeIn`} data-wow-delay="0.5s" style={{ height: '300px' }} />
                </div>
                <div className={`${styles.text}`}>
                    <span className={`${styles.title} wow fadeIn`}>
                        Концепция
                    </span>
                    <span className={`${styles.descr} wow fadeInUp`}>
                        Жизнь в апартаментах Москва Сити представляет
                        собой симбиоз современности, роскоши и
                        функциональности. Этот образ жизни предназначен
                        для тех, кто ценит комфорт и уровень обслуживания,
                        а также стремится быть в самом эпицентре событий
                        и инноваций.
                    </span>
                    <button className={`wow fadeIn`} data-wow-delay="0.5s">Узнать больше</button>
                </div>
            </div>
            <MainGallery isHome={true} />
            <div className={`${styles.block} ${styles.block3}`}>

                <div className={styles.images}>
                    <img src='images/photo2.jpg' className={`wow slideInLeft ${styles.blackImage}`} />
                </div>
                <div className={`${styles.text}`}>
                    <span className={`${styles.title} wow fadeIn`}>
                        Интерьер
                    </span>
                    <span className={`${styles.descr} wow fadeInUp`}>
                        Интерьер апартаментов в Москва Сити пропитан
                        элегантностью и современным стилем. В каждой
                        детали чувствуется забота о комфорте и удовольствии
                        гостей.
                    </span>
                </div>
            </div>
            <div className={`${styles.block} ${styles.block2}`} style={{ marginBottom: '70px' }}>
                <div className={`${styles.text}`}>
                    <span className={`${styles.title} wow fadeIn`}>
                        Сценарии
                    </span>
                    <span className={`${styles.descr} wow fadeInUp`}>
                        Думай позитивно, стакан всегда наполовину полон, всегда
                        Чувствуй хорошее, плохого не существует, между "нет" и "да" выбор только "да"
                        Верь в лучшее, жизнь - это танец под присмотром чуткого Бога
                        Повторяй эту поебень чаще, повторяй, даже если звучит убого
                    </span>
                </div>

                <div className={styles.images}>

                    <img src='images/photo5.jpg' className={`wow slideInRight`} />
                </div>
            </div>
            {/* <div className={`${styles.reserv} wow fadeInRight`}>
                <NavLink className={styles.button} to={'/reservation'}>БРОНИРОВАНИЕ</NavLink>
            </div> */}
            {/* <div className={styles.blockLast}>

            </div> */}
            <Footer />
        </div>
    )
}

export default Home