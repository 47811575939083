import React from 'react'
import style from './EndCard.module.scss'

const EndCard = () => {
    return (
        <div className={`${style.endCard} wow fadeInUp`}>
            <div className={style.top}>
                {/* <div className={style.dots}>
                    <img src='/images/dots.svg' />
                </div> */}
                {/* <div className={style.center}>
                    <img src='/images/last.svg' />
                </div> */}
                <div className={style.dots}>
                    <img src='/images/dots.svg' />
                </div>
            </div>
            <span className={style.soon}>Скоро здесь появятся новые апартаменты.</span>
        </div>
    )
}

export default EndCard