import React from 'react'
import styles from './Card.module.scss'
import { NavLink, useParams } from 'react-router-dom';

interface ICardProps {
    id: string;
}

const Card: React.FC<ICardProps> = ({ id }) => {


    return (
        <NavLink to={`/aparts/${id}`} className={styles.navLink}>

            <div className={`${styles.card} wow fadeInUp`}>
                <div className={styles.photo}>
                    <img src={`/images/photo${Number(id)+1}.jpg`} />
                </div>
                <div className={styles.textContent}>
                    <div className={styles.left}>

                        <div className={styles.propert}>
                            <span>Этаж</span>
                            <span>Площадь</span>
                            <span>Комнаты</span>
                            <span>Категория</span>
                        </div>
                        <div className={styles.set}>
                            <span>30</span>
                            <span>90 м²</span>
                            <span>2</span>
                            <span>Люкс</span>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <span>20000 ₽</span>
                    </div>
                </div>
            </div>
        </NavLink>
    )
}

export default Card